<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>物流详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="card__container">
      <div v-for="(item, index) in saleBill" :key="index">
        <div class="sale__title__container">
          <div class="sale__title">
            第{{ changeNumToString(index + 1) }}批明细：
          </div>
        </div>
        <a-form class="advanced-search-form">
          <a-row>
            <a-col :span="8">
              <a-form-item label="发货时间">
                {{
                  item.logisticsSendTime
                    ? tsFormat(item.logisticsSendTime)
                    : "-"
                }}
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="商品金额">
                {{ calculatePrice(item.detailVoList) }}元
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="物流公司">
                {{ item.logisticsName ? item.logisticsName : "-" }}
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-item label="物流单号">
                {{ item.logisticsSn ? item.logisticsSn : "-" }}
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="物流电话">
                {{ item.logisticsTel ? item.logisticsTel : "-" }}
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item label="发货清单">
                <a-table
                  :columns="inventoryColumns"
                  :data-source="calculateDetail(item.detailVoList)"
                  :loading="loading"
                  :pagination="false"
                  :rowKey="(record, index) => index"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <div v-if="item.receiveBill && item.receiveBill.length > 0">
            <div v-for="ele in item.receiveBill" :key="ele.receivingId">
              <a-row>
                <a-col :span="8">
                  <a-form-item label="收货时间">
                    {{ ele.signTime ? tsFormat(ele.signTime) : "-" }}
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="发票信息">
                    <a>查看</a>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-item label="收货清单">
                    <a-table
                      :columns="inventoryColumns"
                      :data-source="calculateDetail(ele.deliverVoList)"
                      :pagination="false"
                      :rowKey="(record, index) => index"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import {
  fetchReceiveBillBySaleBill,
  fetchSaleBillByOrderSn
} from "@/services/OrderService";
import { tsFormat } from "@/components/DateUtils";
import { changeNumToString } from "@/components/utils";

const inventoryColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "40%"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品序列号",
    dataIndex: "goodsSn",
    width: "15%"
  },
  {
    title: "商品单价（元）",
    dataIndex: "skuPurchasePrice",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "quantity",
    width: "15%"
  }
];
export default {
  name: "DeliverDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      saleBill: [],
      receiveBill: [],
      loading: false,
      inventoryColumns,
      tsFormat
    };
  },
  mounted() {
    this.loadSaleBill();
  },
  methods: {
    changeNumToString,
    // 获取发货单
    loadSaleBill() {
      this.loading = true;
      fetchSaleBillByOrderSn(this.orderSn)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              this.saleBill = data;
              this.saleBill.forEach(item => {
                this.loadReceiveBill(item.consignmentId);
              });
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取收货单
    loadReceiveBill(consignmentId) {
      fetchReceiveBillBySaleBill(consignmentId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          const index = this.saleBill.findIndex(
            item => item.consignmentId === consignmentId
          );
          this.saleBill[index].receiveBill = data;
          this.saleBill = [...this.saleBill];
        }
      });
    },
    // 计算商品金额
    calculatePrice(list) {
      let num = 0;
      if (list && list.length > 0) {
        list.forEach(item => {
          num = num + item.skuPurchasePrice * item.quantity;
        });
      } else {
        num = "-";
      }
      return num;
    },
    // 计算清单合计
    calculateDetail(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let num = 0;
        let price = 0;
        list.forEach(item => {
          price = price + item.skuPurchasePrice * parseInt(item.quantity);
          num = num + parseInt(item.quantity);
        });
        const params = {
          skuName: "合计：",
          skuPurchasePrice: price.toFixed(2),
          quantity: num
        };
        arr.push(params);
      }
      return arr;
    }
  }
};
</script>

<style scoped>
.sale__title {
  width: 100px;
  text-align: right;
  font-weight: bold;
}
</style>
